var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clients-list",on:{"&scroll":function($event){return _vm.onScroll($event.srcElement, _vm.$refs.fixedElement.$el)}}},[_c('screen-header',{attrs:{"title":_vm.$tc('clients.list.title', _vm.brands.length, {
        brand: _vm.currentClient.name,
        count: _vm.brands.length,
      }),"breadcrumbs":_vm.breadcrumbs,"is-loading":_vm.isLoading && !_vm.isScrolling}}),_c('screen-container',{attrs:{"full-height":true}},[_c('screen-grid',{attrs:{"full-height":true}},[_c('screen-card',{attrs:{"full-height":true},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ui-data-search',{ref:"fixedElement",attrs:{"element-fixed":_vm.elementFixed,"scroll-direction":_vm.scrollDirection,"is-mobile":_vm.isMobile,"is-tablet":_vm.isTablet,"is-loading":_vm.isLoading,"is-scrolling":_vm.isScrolling,"creation-roles":[_vm.userRoles.superAdmin],"withAddButton":true,"view":"clients"},on:{"add":_vm.addClient,"search":_vm.onSearch}}),_c('div',{staticClass:"clients-list__list"},[_c('ui-data-list',{attrs:{"items":_vm.clientsMobile,"is-loading":_vm.isLoading,"is-scrolling":_vm.isScrolling,"no-more-data":_vm.noMoreData},on:{"click":function($event){return _vm.goToClient({ row: { id: $event.id } })}},scopedSlots:_vm._u([{key:"skeleton",fn:function(){return [_c('div',{staticClass:"clients-list__list__content"},[_c('skeleton-line',{attrs:{"height":"18px","width":"80%"}}),_c('skeleton-line',{attrs:{"height":"15px","width":"50%","margin-top":"3px"}})],1),_c('skeleton-icon',{attrs:{"margin-left":"16px"}})]},proxy:true},{key:"item",fn:function(slotProps){return [_c('div',{staticClass:"clients-list__list__content"},[_c('div',{staticClass:"clients-list__list__content__name"},[_vm._v(" "+_vm._s(slotProps.item.name)+" ")])]),_c('span',{staticClass:"clients-list__list__status backoffice-icons",class:[("clients-list__list__status--" + (slotProps.item.status))]},[(
                      slotProps.item.status === '1' ||
                      slotProps.item.status === '10' ||
                      slotProps.item.status === '20'
                    )?[_vm._v(" success ")]:[_vm._v("error")]],2)]}}])})],1),_c('div',{staticClass:"clients-list__table"},[_c('ui-data-table',{attrs:{"columns":_vm.columns,"rows":_vm.clients,"total-rows":_vm.totalRows,"is-loading":_vm.isLoading || _vm.isUpdating,"pagination":_vm.clientsPagination},on:{"loadItems":function($event){return _vm.loadClients($event, false, true)},"onCellClick":_vm.goToClient},scopedSlots:_vm._u([{key:"data-table-row",fn:function(props){return [_c('router-link',{staticClass:"clients-list__table__link",attrs:{"to":{ name: 'Client', params: { id: props.row['id'] } }}}),(props.column.field === 'name')?_c('span',[_c('div',{staticClass:"clients-list__table__block"},[_c('div',{staticClass:"clients-list__table__block__content"},[_c('div',{staticClass:"clients-list__table__block__content__name"},[_vm._v(" "+_vm._s(props.row['name'])+" ")])])])]):_vm._e(),(props.column.field === 'gatoreviewsId')?_c('span',[_vm._v(" "+_vm._s(props.row['gatoreviewsId'])+" ")]):_vm._e(),(props.column.field === 'localeName')?_c('span',[_vm._v(" "+_vm._s(props.row['localeName'])+" ")]):(props.column.field === 'isMultilanguage')?_c('span',[_c('span',{staticClass:"clients-list__table__status backoffice-icons",class:[("clients-list__table__status--" + (props.row['isMultilanguage']))]},[(props.row['isMultilanguage'])?[_vm._v(" success ")]:[_vm._v("error")]],2)]):(props.column.field === 'status')?_c('span',[_c('span',{staticClass:"clients-list__table__status backoffice-icons",class:[("clients-list__table__status--" + (props.row['status']))]},[(props.row['status'] === '1')?[_vm._v(" success ")]:[_vm._v("error")]],2)]):_vm._e()]}}])})],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }