<template>
  <div class="clients-list" @scroll.passive="onScroll($event.srcElement, $refs.fixedElement.$el)">
    <screen-header
      :title="
        $tc('clients.list.title', brands.length, {
          brand: currentClient.name,
          count: brands.length,
        })
      "
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
    />
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <ui-data-search
              :element-fixed="elementFixed"
              :scroll-direction="scrollDirection"
              :is-mobile="isMobile"
              :is-tablet="isTablet"
              :is-loading="isLoading"
              :is-scrolling="isScrolling"
              :creation-roles="[userRoles.superAdmin]"
              :withAddButton="true"
              view="clients"
              ref="fixedElement"
              @add="addClient"
              @search="onSearch"
            />
            <div class="clients-list__list">
              <ui-data-list
                :items="clientsMobile"
                :is-loading="isLoading"
                :is-scrolling="isScrolling"
                :no-more-data="noMoreData"
                @click="goToClient({ row: { id: $event.id } })"
              >
                <template v-slot:skeleton>
                  <div class="clients-list__list__content">
                    <skeleton-line height="18px" width="80%" />
                    <skeleton-line height="15px" width="50%" margin-top="3px" />
                  </div>
                  <skeleton-icon margin-left="16px" />
                </template>
                <template v-slot:item="slotProps">
                  <div class="clients-list__list__content">
                    <div class="clients-list__list__content__name">
                      {{ slotProps.item.name }}
                    </div>
                  </div>
                  <span
                    class="clients-list__list__status backoffice-icons"
                    :class="[`clients-list__list__status--${slotProps.item.status}`]"
                  >
                    <template
                      v-if="
                        slotProps.item.status === '1' ||
                        slotProps.item.status === '10' ||
                        slotProps.item.status === '20'
                      "
                    >
                      success
                    </template>
                    <template v-else>error</template>
                  </span>
                </template>
              </ui-data-list>
            </div>
            <div class="clients-list__table">
              <ui-data-table
                :columns="columns"
                :rows="clients"
                :total-rows="totalRows"
                :is-loading="isLoading || isUpdating"
                :pagination="clientsPagination"
                @loadItems="loadClients($event, false, true)"
                @onCellClick="goToClient"
              >
                <template slot="data-table-row" slot-scope="props">
                  <router-link
                    class="clients-list__table__link"
                    :to="{ name: 'Client', params: { id: props.row['id'] } }"
                  />
                  <span v-if="props.column.field === 'name'">
                    <div class="clients-list__table__block">
                      <div class="clients-list__table__block__content">
                        <div class="clients-list__table__block__content__name">
                          {{ props.row['name'] }}
                        </div>
                      </div>
                    </div>
                  </span>
                  <span v-if="props.column.field === 'gatoreviewsId'">
                    {{ props.row['gatoreviewsId'] }}
                  </span>
                  <span v-if="props.column.field === 'localeName'">
                    {{ props.row['localeName'] }}
                  </span>
                  <span v-else-if="props.column.field === 'isMultilanguage'">
                    <span
                      class="clients-list__table__status backoffice-icons"
                      :class="[`clients-list__table__status--${props.row['isMultilanguage']}`]"
                    >
                      <template v-if="props.row['isMultilanguage']"> success </template>
                      <template v-else>error</template>
                    </span>
                  </span>
                  <span v-else-if="props.column.field === 'status'">
                    <span
                      class="clients-list__table__status backoffice-icons"
                      :class="[`clients-list__table__status--${props.row['status']}`]"
                    >
                      <template v-if="props.row['status'] === '1'"> success </template>
                      <template v-else>error</template>
                    </span>
                  </span>
                </template>
              </ui-data-table>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { userRoles } from '@/config/permissions.config'

export default {
  name: 'ClientsList',
  components: {
    UiDataTable,
    UiDataList,
    UiDataSearch,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
  },
  mixins: [onScroll],
  data() {
    return {
      search: '',
      isLoading: false,
      isScrolling: false,
      isUpdating: false,
      noMoreData: false,
      totalRows: 0,
      userRoles,
    }
  },
  mounted() {
    if (this.brands.length === 1) {
      this.goToClient({
        row: this.brands[0],
      })
    } else {
      this.loadClients(null, true)
    }
  },
  computed: {
    ...mapState({
      brands: state => state.user.brands,
      clients: state => state.client.clients,
      clientsMobile: state => state.client.clientsMobile,
      clientsPagination: state => state.client.clientsPagination,
      currentClient: state => state.user.currentClient,
    }),
    noData() {
      return this.isLoading || this.noMoreData
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('clients.list.breadcrumb'),
          route: {
            name: 'Clients',
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('clients.list.column.client'),
          field: 'name',
          sortable: false,
        },
        {
          label: this.$t('clients.list.column.gatoreviewsId'),
          field: 'gatoreviewsId',
        },
        {
          label: this.$t('clients.list.column.localeName'),
          field: 'localeName',
          sortable: false,
        },
        {
          label: this.$t('clients.list.column.isMultilanguage'),
          field: 'isMultilanguage',
        },
        {
          label: this.$t('clients.list.column.status'),
          field: 'status',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getClients: 'client/getClients',
    }),
    goToClient(e) {
      this.$router.push({ name: 'Client', params: { id: e.row.id } })
    },
    onSearch(value) {
      this.search = value
      this.loadClients(null, true, true)
    },
    loadClients(tableParams = null, resetClients = false, updating = false) {
      if (updating) {
        this.isUpdating = true
      } else {
        this.isLoading = true
      }
      this.getClients({ tableParams, resetClients, search: this.search })
        .then(() => {
          this.totalRows = this.clientsPagination.nbResults
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
        .catch(() => {
          this.noMoreData = true
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
    },
    addClient() {
      this.$router.push({ name: 'ClientCreate' })
    },
  },
}
</script>

<style lang="scss" scoped>
.clients-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }
  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }
    &__content {
      flex: 1;
      &__name {
        font-weight: 600;
      }
      &__address {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    &__link {
      @include full-link;
    }
    &__status {
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20,
      &--true {
        color: map-get($generic-color-variants, 'success');
      }
    }
    &__block {
      display: flex;
      align-items: center;
      &__logo {
        width: 36px;
        height: 36px;

        @media (min-width: $screen-md) {
          margin-right: $gutter-tablet;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          font-weight: 600;
        }
        &__address {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-default;
          font-style: normal;
        }
      }
      &__icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        color: map-get($generic-color-variants, 'influence');
        &:first-child {
          margin-right: 8px;
        }
        &--inactive {
          color: $generic-color-bg-disabled;
        }
      }
    }
  }
}
</style>
